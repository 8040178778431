import React from "react";
import {RouteComponentProps} from "react-router";
import {NoticiaFuente} from "../../../models";
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  Nav,
  NavItem,
  NavLink,
  Row
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as FA from "@fortawesome/free-solid-svg-icons";
import {permiso, Permisos} from "../../../const/permisos";
import {BaseURL, Headers} from "../../../http";
import {AppRoutes, n} from "../../../const";
import Dropzone from "dropzone";
import moment, {Moment} from "moment";
import {Response} from "../../../models/Response";
import ReactDatetime from "react-datetime";
import makeAnimated from "react-select/animated";
import Select from "react-select";


Dropzone.autoDiscover = false;


interface NuevaProps extends RouteComponentProps {
  mostrar: boolean;

  cancelar(): void;
}

interface NuevaState {
  noticia: {
    titulo: string;
    comentarios: string;
    fecha: Moment;
    enlace: string;
    fuente: NoticiaFuente | null;
  };
  fuentes: NoticiaFuente[];
  creando: boolean;
  invalidos: string[];
  imagen: boolean;
}

const estadoInicial: NuevaState = {
  noticia: {
    titulo: "",
    comentarios: "",
    fecha: moment.utc(moment.utc().format("YYYY-MM-DD")),
    enlace: "",
    fuente: null
  },
  fuentes: [],
  creando: false,
  invalidos: [],
  imagen: false
};

export class Nueva extends React.Component<NuevaProps, NuevaState> {
  constructor(props: NuevaProps) {
    super(props);

    this.state = estadoInicial;
  }

  private inicio(): void {
    let currentSingleFile: any = undefined;
    let elemento = document.getElementById("dropzone-single");
    let zona = document.getElementsByClassName("dz-preview-single")[0] as HTMLElement;
    if (elemento === null || zona === null)
      return;
    new Dropzone(elemento, {
      url: BaseURL + "/azure/blob/imagen",
      headers: {...Headers, 'Content-Type': undefined},
      method: "PUT",
      paramName: "file",
      previewsContainer: zona,
      previewTemplate: zona.innerHTML,
      maxFiles: 1,
      acceptedFiles: "image/*",
      init: function () {
        this.on("addedfile", (file) => {
          if (currentSingleFile) {
            this.removeFile(currentSingleFile);
          }
          currentSingleFile = file;
        });
      },
    }).on('success', (file, response: Response) => {
      if (!response.success)
        return;
      this.setState({noticia: {...this.state.noticia, enlace: response.data.descripcion}});
    });
    zona.innerHTML = "";
  }

  private valido(): boolean {
    if (this.state.invalidos.length > 0)
      return false;
    if (this.state.noticia.titulo === "" || this.state.noticia.enlace === "" || this.state.noticia.fuente === null)
      return false;
    return permiso(Permisos.gestionNoticias);
  }

  private crear(): void {
    if (!this.valido())
      return;

    this.setState({creando: true}, () => fetch(BaseURL + "/noticias", {
      headers: Headers,
      method: "POST",
      body: JSON.stringify(this.state.noticia)
    })
      .then(() => {
        n("Listo", "Se ha enlazado la noticia", "success");
        this.setState({...estadoInicial, fuentes: this.state.fuentes}, () => {
          this.props.cancelar();
          this.props.history.push(AppRoutes.Panel);
          setTimeout(() => this.props.history.push(AppRoutes.Noticias), 100);
        });
      })
      .catch(() => n("Error", "Hubo un error creando la noticia", "danger")));

  }

  componentDidMount(): void {
    this.setState(estadoInicial, () => fetch(BaseURL + "/noticias/fuentes", {headers: Headers})
      .then(res => res.json())
      .then(res => this.setState({fuentes: res})));
  }

  render() {
    return <Modal
      className="modal-dialog-centered" isOpen={this.props.mostrar}
      toggle={() => this.props.cancelar()}
    >
      <div className="modal-header">
        <h4 className="modal-title" id="modal-title-default">
          Nuevo Vehículo
        </h4>
        <button aria-label="Cerrar"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.props.cancelar()}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col md="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-titular">
                Titular
              </label>
              <Input
                className={"form-control-alternative" + (this.state.invalidos.indexOf('titulo') > -1 ? " form-control-alternative-error" : "")}
                id="input-titular"
                placeholder="Titular de la noticia"
                type="text"
                onChange={e => {
                  let invalidos = [...this.state.invalidos];
                  if (e.target.value === "" && this.state.invalidos.indexOf("titulo") === -1)
                    invalidos = [...this.state.invalidos, "titulo"];
                  else if (e.target.value !== "" && this.state.invalidos.indexOf("titulo") > -1)
                    invalidos.splice(this.state.invalidos.indexOf("titulo"));

                  this.setState({
                    noticia: {
                      ...this.state.noticia,
                      titulo: e.target.value
                    },
                    invalidos: invalidos
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-comentarios">
                Comentarios
              </label>
              <Input
                className={"form-control-alternative" + (this.state.invalidos.indexOf('comentarios') > -1 ? " form-control-alternative-error" : "")}
                id="input-comentarios"
                placeholder="Notas sobre la noticia"
                type="text"
                onChange={e => {
                  this.setState({
                    noticia: {
                      ...this.state.noticia,
                      comentarios: e.target.value
                    }
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col xs="12" md="7">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-fuente">
                Fuente
              </label>
              <Select
                value={this.state.noticia.fuente ? {
                  label: this.state.noticia.fuente.nombre,
                  value: this.state.noticia.fuente.id
                } : null}
                options={this.state.fuentes.map(f => ({label: f.nombre, value: f.id}))}
                components={makeAnimated()}
                placeholder="Fuente" className="select-input" classNamePrefix="select-input-prefix"
                onChange={(e: any | null | undefined) => {
                  if (!e) {
                    e = null;
                  }

                  let fuente: NoticiaFuente | null | undefined = null;
                  if (e) {
                    fuente = this.state.fuentes.find(v => v.id === e.value);
                  }
                  if (fuente === undefined) {
                    fuente = null;
                  }
                  this.setState({noticia: {...this.state.noticia, fuente: fuente}});
                }}
              />
            </FormGroup>
          </Col>
          <Col xs="12" md="5">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-fecha">
                Fecha
              </label>
              <InputGroup className="input-group-alternative" id="input-fecha">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <FontAwesomeIcon icon={FA.faCalendar}/>
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{placeholder: "Fecha"}}
                  timeFormat={false}
                  value={this.state.noticia.fecha.local()}
                  onChange={(e: any) => {
                    if (typeof e === typeof "" || e === null || e === undefined)
                      return;
                    this.setState({noticia: {...this.state.noticia, fecha: e}});
                  }}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <Nav
              className="nav-fill flex-column flex-md-row mb-4"
              id="tabs-icons-text"
              pills
              role="tablist"
            >
              <NavItem>
                <NavLink
                  style={{
                    backgroundColor: (!this.state.imagen ? "var(--gray-dark)" : undefined),
                    color: (!this.state.imagen ? undefined : "var(--gray-dark)")
                  }}
                  aria-selected={!this.state.imagen}
                  className={"mb-sm-3 mb-md-0" + (!this.state.imagen ? " active" : "")}
                  onClick={() => this.setState({imagen: false, noticia: {...this.state.noticia, enlace: ""}})}
                  role="tab"
                  href="#"
                >
                  <FontAwesomeIcon icon={FA.faAnchor}/> Enlace
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{
                    backgroundColor: (this.state.imagen ? "var(--gray-dark)" : undefined),
                    color: (this.state.imagen ? undefined : "var(--gray-dark)")
                  }}
                  aria-selected={this.state.imagen}
                  className={"mb-sm-3 mb-md-0" + (this.state.imagen ? " active" : "")}
                  onClick={() => {
                    this.setState({imagen: true, noticia: {...this.state.noticia, enlace: ""}}, () => this.inicio());
                  }}
                  role="tab"
                  href="#"
                >
                  <FontAwesomeIcon icon={FA.faImage}/> Imagen
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
        </Row>

        {!this.state.imagen ? <Row>
          <Col md="12">
            <FormGroup>
              <label className="form-control-label" htmlFor="input-enlace">
                Enlace
              </label>
              <Input
                className={"form-control-alternative" + (this.state.invalidos.indexOf('enlace') > -1 ? " form-control-alternative-error" : "")}
                id="input-enlace"
                placeholder="Enlace a la noticia"
                type="text"
                onChange={e => {
                  let invalidos = [...this.state.invalidos];
                  if (e.target.value === "" && this.state.invalidos.indexOf("enlace") === -1)
                    invalidos = [...this.state.invalidos, "enlace"];
                  else if (e.target.value !== "" && this.state.invalidos.indexOf("enlace") > -1)
                    invalidos.splice(this.state.invalidos.indexOf("enlace"));

                  this.setState({
                    noticia: {
                      ...this.state.noticia,
                      enlace: e.target.value
                    },
                    invalidos: invalidos
                  });
                }}
              />
            </FormGroup>
          </Col>
        </Row> : <Row>
          <Col>
            <div className="dropzone dropzone-single mb-3" id="dropzone-single">
              <div className="fallback">
                <div className="custom-file">
                  <input className="custom-file-input" id="projectCoverUploads" type="file"/>
                  <label className="custom-file-label" htmlFor="projectCoverUploads">
                    Escoger imagen
                  </label>
                </div>
              </div>
              <div className="dz-preview dz-preview-single">
                <div className="dz-preview-cover">
                  <div className="dz-progress">
                                <span
                                  className="dz-upload"
                                  data-dz-uploadprogress={true}
                                  style={{backgroundColor: "black", height: 5}}
                                />
                  </div>
                  <img alt="..." className="dz-preview-img" data-dz-thumbnail=""/>
                </div>
              </div>
              <div className="dz-message">
                Arrastra una imagen, o clica para seleccionar una
              </div>
            </div>
          </Col>
        </Row>}
      </div>
      <div className="modal-footer">
        <Button
          color="link" data-dismiss="modal" type="button" className="text-gray-dark"
          onClick={() => this.props.cancelar()} disabled={this.state.creando}
        >
          Cerrar
        </Button>
        <Button
          className="ml-auto" color="gray-dark" type="button"
          onClick={() => this.crear()} disabled={!this.valido() || this.state.creando}
        >
          {this.state.creando ? <FontAwesomeIcon icon={FA.faSpinner} spin/> : <>Crear</>}
        </Button>
      </div>
    </Modal>;
  }
}


export default Nueva;